import { template as template_a6ecd83608254a6b990e9c6f0f3554ee } from "@ember/template-compiler";
const WelcomeHeader = template_a6ecd83608254a6b990e9c6f0f3554ee(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
