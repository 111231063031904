import { template as template_635d81f7690a441d8a10d492e9bf16e8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_635d81f7690a441d8a10d492e9bf16e8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
